@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@1,400;1,600;1,700&family=Poppins&family=Roboto&display=swap");

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.546);
  display: flex;
  justify-content: center;
  align-items: center;
}

.range-inputs {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.range-inputs input {
  width: 45%;
  padding: 2px;
  box-sizing: border-box;
  padding-left: 7rem;
}

.modal-content {
  background: #fffffff2;
  padding: 1rem;
  border-radius: 5px;
  width: 50rem;
  font-family: "Poppins", sans-serif;
  overflow-y: auto;
  position: relative;
  max-height: 95vh;
}
.modal-content h4 {
  text-align: center;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.close-button {
  position: absolute;
  top: 0px;
  left: 0rem;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 30px;
  color: #555;
}

.modal-filters-container {
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem 1rem 1rem;
  margin-top: 0.5rem;
}

.filter-section {
  margin-bottom: 2.5rem;
  /* border: 2px solid black; */
  font-family: "Poppins", sans-serif;
}

label {
  font-weight: 500;
  font-size: 26px;
}

.input-range {
  width: 100%;
  margin-top: 10px;
  padding-left: 2rem;
}

.input-range__track {
  background: #5d5a5a;
}

.input-range__track--active {
  background: #428bca;
}

.input-range__slider {
  background: #428bca;
  border: 2px solid #fff;
}

.input-range__label {
  color: #0b0a0a;
}
.selectable-box {
  display: inline-flex;
  align-items: center;
  padding: 10px;
  margin: 5px;
  border: 2px solid #ccc;
  cursor: pointer;
  border-radius: 5px;
}

.selectable-box.selected {
  border-color: #428bca; /* Color when selected */
}

.amenities-checkboxes {
  display: flex;
  flex-wrap: wrap;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.amenity-checkbox {
  margin-right: 2rem;
  width: 16rem;
  /* display: flex; */
  /* align-items: center; */
  padding-left: 2rem;
  padding-right: 2rem;
}

.amenitieslabel {
  font-size: 18px;
  padding: 0.5rem;
}

.filter-buttons {
  display: flex;
  justify-content: space-between;
  padding-left: 2rem;
}

.clear-button {
  background-color: #ccc;
  color: #333;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.icon-box {
  padding-left: 2rem;
}
