.header {
  width: 100%;
  margin: 0 auto;
  height: 5.75rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: #ffff;
}
.header img[alt="logo"] {
  object-fit: contain;
  height: 80%;
  width: 6rem;
}

.search_filter {
  /* background-color: azure; */
  height: 50%;
  width: 55%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.searchbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 30px;
  height: 100%;
  width: 90%;
  display: flex;
  box-shadow: 1px 1px 10px lightgray;
}
.search {
  border: transparent;
  border-radius: 25px;
  height: 2rem;
  width: 35%;
  padding: 1rem;
}
.search:nth-child(2) {
  padding: 0%;
}
.search:nth-child(3) {
  width: 20%;
}
.date_picker {
  border: transparent;
  border-radius: 25px;
}
input:hover::placeholder {
  color: green;
}
input::placeholder {
  color: rgba(1, 1, 1, 0.512);

  font-size: large;
}
.searchicon:hover {
  box-shadow: 2px 2px 5px black;
}
.search:hover,
.date_picker:hover {
  background-color: rgba(149, 139, 139, 0.318);
  cursor: pointer;
}

.filter {
  font-size: 2rem;
  cursor: pointer;
}

.web_logo {
  padding: 1rem;
  color: #000;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: fit-content;
}

/* ////////////// body /////////// */

.propertylist {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin: 0 auto;
  margin-top: 2rem;
  width: 95%;
}
.property {
  position: relative;
  width: 100%;
  height: 25rem;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: 2px 2px 10px rgba(15, 15, 15, 0.81);
}

.property img[alt="Propertyimg"] {
  object-fit: cover;
  width: 100%;
  height: 90%;
}

.property h4 {
  height: 10%;
  padding: 0.5rem;
  font-size: 0.9rem;
  text-align: center;
}
.property:hover {
  box-shadow: 4px 4px 20px rgba(15, 15, 15, 0.81);
}
.property figcaption {
  position: absolute;
  inset: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease-in-out, z-index 0s ease-in-out;
}

figure:hover figcaption {
  opacity: 1;
  z-index: 1;
  background: linear-gradient(180deg, rgba(21, 22, 24, 0.31) 0%, #151618 100%);
  transition-delay: 0.1s;
}

figure:hover main {
  color: #ffff;
}

figure img {
  transform: scale(1);
  transition: transform 0.3s ease-in-out;
}

figure:hover img {
  transform: scale(1.2);
}

figure:hover main {
  transform: translate3d(0, 0, 0);
}

.property figcaption h5,
.property figcaption h6 {
  margin-bottom: 10%;
}

.price {
  font-family: "Playfair Display", serif;
  margin: 0;
}

footer {
  display: none;
}

.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
}

#dropdownMenuLink {
  display: flex;
  flex-direction: row;
  height: 50px;
  border-radius: 50% 10px 10px 50%;
  justify-content: center;
  align-items: center;
  padding-left: 0px;
}

.dropdown:hover > #dropdownMenuLink {
  background-color: lightgrey;
}

.dropdown-toggle::after {
  font-size: 1.5rem;
  transform: rotate(-90deg); /* Adjust the rotation angle as needed */
}

.dropdown-toggle:hover::after {
  transform: rotate(0);
}

.user-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.pagination {
  width: 40%;
  display: flex;
  text-align: center;
  justify-content: space-evenly;
  margin: 1.5rem auto 4.5rem auto;
}

.pagination_disable {
  display: none;
}

.pagination button {
  display: flex;
  align-items: center;
  border-radius: 50%;
  border: none;
  height: 3rem;
  width: 3rem;
}
.pagination button:nth-child(1) {
  background: linear-gradient(to right, #0e8b53, #0e8b53);
  transition: background-size 0.2s;
  background-size: 0% 100%;
  background-repeat: no-repeat;
}
.pagination button:nth-child(2) {
  background: linear-gradient(to left, #0e8b53, #0e8b53) right;
  transition: background-size 0.2s;
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

.pagination button:not([disabled]):hover {
  color: white;
  background-size: 100% 100%;
}

.pagination button span {
  margin: 0 auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

footer {
  display: flex;
  justify-content: center;
  height: 40px;
  box-shadow: 2px 2px 5px rgb(30, 28, 28);
  border-top: 1px solid grey;
  font-size: small;
}

.footerlist {
  display: flex;
  gap: 2rem;
  margin-right: 31rem;
}

.not_found {
  width: 50%;
  height: 50vh;
  display: block;
  margin: 5% auto;
  text-align: center;
}
