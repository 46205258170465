/* Modal.css */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: rgba(255, 255, 255, 0.945);
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-height: 95vh; /* Set a maximum height */
  overflow-y: auto; /* Enable vertical scrolling if needed */
  width: 50rem;
}

.close-button {
  top: 0rem;
  left: 19.4rem;
  background-color: transparent;
  border: none;
  font-size: 50px;
  cursor: pointer;
  position: fixed;
}

.modal-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.modal-images-container img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}
